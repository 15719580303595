/* Ensure the styles are properly imported in your component */

.container-xxl {
  max-width: 100%;
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0d6efd !important;
}

.top-0 {
  top: 0 !important;
}

.start-0 {
  left: 0 !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
}

.fs-5 {
  font-size: 1.25rem;
}

.g-5 > * {
  margin-bottom: 2rem;
}

.g-4 > * {
  margin-bottom: 1.5rem;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

@import '../../sass/colors.scss';

.facts-counter {
    background-image: url('../../assets/carousel-2.jpg'); // Ensure the path is correct
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // color: #fff;
    padding: 50px;
}

.facts-counter .row .col-sm-6 h1 {
    font-size: 48px;
    font-weight: bold;
    // color: #fff;
}

.facts-counter .row .col-sm-6 p {
    font-size: 18px;
    // color: #f1f1f1;
}

.facts-counter .row .col-sm-6 {
    margin-bottom: 30px;
}